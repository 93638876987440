dmx.Component('medium-editor', {

  initialData: {
    value: '',
  },

  attributes: {
    editable: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: 'Type your text',
    },

    static: {
      type: Boolean,
      default: false,
    },

    align: {
      type: String,
      default: 'center',
      enum: ['left', 'center', 'right'],
    },

    buttons: {
      type: [String, Array],
      default: ['bold', 'italic', 'underline', 'anchor', 'h2', 'h3', 'quote'],
      // bold|italic|underline|strikethrough|subscript|superscript|anchor|image|quote|pre|orderedlist|unorderedlist|indent|outdent|justifyLeft|justifyCenter|justifyRight|justifyFull|h1|h2|h3|h4|h5|h6|removeFormat|html
    },

    disableReturn: {
      type: Boolean,
      default: false,
    },

    disableDoubleReturn: {
      type: Boolean,
      default: false,
    },

    disableExtraSpaces: {
      type: Boolean,
      default: false,
    },

    disableSpellcheck: {
      type: Boolean,
      default: false,
    },

    targetBlank: {
      type: Boolean,
      default: false,
    },

    autoLink: {
      type: Boolean,
      default: false,
    },

    autoList: {
      type: Boolean,
      default: false,
    },

    fontawesome: {
      type: Boolean,
      default: false,
    },

    value: {
      type: String,
      default: null,
    },
  },

  methods: {
    setValue (value) {
      this._setValue(value);
    },
  },

  events: {
    changed: Event,
    updated: Event,
  },

  render () {
    let value = this._getValueFromDOM();

    if (value.includes('{{')) {
      this.$watch(value, this._setValue.bind(this));
    }

    if (this.props.value) {
      this._setValue(this.props.value);
    }

    this._initEditor();
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('value')) {
      this._setValue(this.props.value);
    }

    this._destroyEditor();

    if (this.$node.tagName == 'TEXTAREA' || this.props.editable) {
      this._initEditor();
    }
  },

  destroy () {
    this._destroyEditor();
  },

  _setValue (value) {
    if (this._editor) {
      this._editor.setContent(value);
    } else if (this.$node.tagName == 'TEXTAREA') {
      this.$node.value = value;
    } else {
      this.$node.innerHTML = value;
    }

    this._updateHander();
  },

  _initEditor () {
    if (this._editor) return;

    let buttons = this.props.buttons;

    if (typeof buttons == 'string') {
      if (buttons[0] == '[') {
        buttons = dmx.parse(buttons);
      } else {
        buttons = buttons.split(/\s*,\s*/g);
      }
    }

    this._editor = new MediumEditor(this.$node, {
      elementsContainer: this.$node.closest('.modal, .offcanvas, .offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl') || document.body,
      buttonLabels: this.props.fontawesome ? 'fontawesome' : false,
      disableReturn: this.props.disableReturn,
      disableDoubleReturn: this.props.disableDoubleReturn,
      disableExtraSpaces: this.props.disableExtraSpaces,
      spellcheck: !this.props.disableSpellcheck,
      targetBlank: this.props.targetBlank,
      autoLink: this.props.autoLink,
      toolbar: {
        static: this.props.static,
        sticky: this.props.static,
        align: this.props.align,
        updateOnEmptySelection: this.props.static,
        buttons: buttons,
      },
      anchorPreview: {
        showWhenToolbarIsVisible: this.props.static,
      },
      placeholder: {
        text: this.props.placeholder,
        hideOnClick: false,
      },
      extensions: this._initExtensions(),
    });

    this._editor.dmxComponent = this;

    this._editor.subscribe('editableInput', this._updateHander.bind(this));
  },

  _destroyEditor () {
    if (this._editor) {
      this._editor.destroy();
      delete this._editor;
    }
  },

  _initExtensions () {
    let extensions = {
      imageDragging: {},
    };

    Object.keys(dmx.mediumEditor.extensions).forEach(name => {
      let extension = dmx.mediumEditor.extensions[name];
      let config = {};

      if (name == 'auto-list' && !this.props.autoList) return;
      if (name == 'toolbar') return;

      if (this.$node.hasAttribute('medium:' + name)) {
        try {
          config = dmx.parse(this.$node.getAttribute('medium:' + name));
        } catch (e) {
          console.warn('Invalid config for ' + name);
        }
      } else if (name == 'image-upload') {
        // only external extension, only apply when attribute is on element
        return;
      }

      extensions[name] = new extension(config);
    });

    let buttons = this.props.buttons;

    if (typeof buttons == 'string') {
      if (buttons[0] == '[') {
        buttons = dmx.parse(buttons);
      } else {
        buttons = buttons.split(/\s*,\s*/g);
      }
    }

    extensions.toolbar = new dmx.mediumEditor.extensions.toolbar({
      static: this.props.static,
      sticky: this.props.static,
      align: this.props.align,
      updateOnEmptySelection: this.props.static,
      buttons: buttons,
    });

    return extensions;
  },

  _getValueFromDOM () {
    let value = this.$node.tagName == 'TEXTAREA' ? this.$node.value : this.$node.innerHTML;
    if (value) value = value.trim();
    return value;
  },

  _updateHander (event) {
    if (this._editor) {
      this.set('value', this._editor.getContent());
    } else {
      this.set('value', this._getValueFromDOM());
    }

    dmx.nextTick(() => {
      this.dispatchEvent('updated');
      if (event instanceof Event) this.dispatchEvent('changed');
    });
  },

});
